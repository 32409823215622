<template>
  <div class="jumbotron">
    <h1 class="display-3">{{ title }}</h1>
    <p v-if="subtitle" class="lead">{{ subtitle }}</p>
    <img :src="image" alt="Team Image" class="team-image" />
  </div>
</template>

<script>
export default {
  name: 'Jumbotron',
  data() {
    return {
      titles: {
        faqs: {
          title: "FAQS",
        },
        compradominio: {
          title: "Registro de dominio en línea",
          subtitle:
            "Puedes registrar y administrar tus dominios desde nuestra web, fácil, rápido y seguro",
        },
        pasosdominio: {
          title: "PASOS PARA OBTENER UN DOMINIO",
        },
        requisitos: {
          title: "PASOS PARA REGISTRAR UN DOMINIO",
        },
      },
    };
  },
  computed: {
    namespace() {
      if (!this.$route || !this.$route.name) return "";
      return this.$route.name.toLowerCase();
    },
    image() {
      return "/static/" + this.namespace + "-team.jpg";
    },
    subtitle() {
      return this.titles[this.namespace] && this.titles[this.namespace].subtitle;
    },
    title() {
      return this.titles[this.namespace] && this.titles[this.namespace].title;
    },
  },
};
</script>

<style scoped>
.jumbotron {
  text-align: center;
  padding: 50px;
  background-color: #f0f4f8;
}

.display-3 {
  font-size: 48px !important;
  font-weight: bold;
  color: #333;
}

.lead {
  font-size: 20px;
  color: #666;
}

.team-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-top: 30px;
  margin-bottom: -40px;
}

@media (max-width: 768px) {
  .display-3 {
    font-size: 36px !important;
  }
  .lead {
    font-size: 18px;
  }
}

@media (max-width: 600px) {
  .jumbotron {
    padding: 30px;
  }
  .display-3 {
    font-size: 28px !important;
  }
  .lead {
    font-size: 14px;
  }
  .team-image {
    margin-top: 20px;
    margin-bottom: 0;
  }
}
</style>
